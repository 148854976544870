import { render, staticRenderFns } from "./websideworker.vue?vue&type=template&id=25d2592f&scoped=true"
import script from "./websideworker.vue?vue&type=script&lang=js"
export * from "./websideworker.vue?vue&type=script&lang=js"
import style0 from "./websideworker.vue?vue&type=style&index=0&id=25d2592f&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d2592f",
  null
  
)

export default component.exports